<template>
	<!-- Element LAyout -->
	<el-row class="container">
		<!-- <el-col
			:span="12"
			class="hidden-sm-and-down">
			<el-image
				class="cover-image"
				:src="require('@/assets/order-plus-icon.png')"
				:fit="`contain`"></el-image>
		</el-col> -->
		<el-col
			:md="24"
			:sm="24"
			class="router-view-container"
		>
			<router-view />
		</el-col>
	</el-row>
	<!-- End Layout -->
</template>

<style lang="scss" scoped>
.container {
	height: 100vh;
	.cover-image {
		width: 100%;
		height: 100%;
	}
}
</style>